<template>
	<transition name="page" mode="out-in">
		<header v-if="headingImage" :key="headingImage.image" :class="{ subpage: !route.name.startsWith('index') }">
			<slot name="extra-content" />
			<div class="slider">
				<div class="slides">
					<picture>
						<source
							v-if="headingImage.imagePortraitWebp"
							:srcset="headingImage.imagePortraitWebp"
							media="(max-width: 500px)"
							type="image/webp"
						/>
						<source
							v-if="headingImage.imagePortrait"
							:srcset="headingImage.imagePortrait"
							media="(max-width: 500px)"
						/>
						<source v-if="headingImage.imageWebp" :srcset="headingImage.imageWebp" type="image/webp" />
						<source :srcset="headingImage.image" />
						<img class="slider-image" :src="headingImage.image" :alt="headingImage.imageAlt" />
					</picture>
				</div>
			</div>
		</header>
	</transition>
</template>

<script setup>
const route = useRoute();

defineProps({
	headingImage: { type: Object, default: () => {} },
});
</script>

<style lang="scss" scoped>
header {
	position: relative;
	overflow: hidden;
	z-index: 1;
}

.slider,
.slider .slides {
	height: 100vh;
	position: relative;
}

.subpage .slider,
.subpage .slider .slides {
	height: 60vh;
}

.slides::after {
	content: '';
	position: absolute;
	inset: 0;
	z-index: 1;
	background: rgb(0 0 0 / 20%);
}

.slider-image {
	height: 100%;
	width: 100%;
	object-fit: cover;
	animation: fade-in 800ms ease-out;
}

@keyframes fade-in {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

@media (min-width: 961px) {
	.subpage .slider,
	.subpage .slider .slides {
		min-height: 100px;
	}
}

@media (max-width: 720px) {
	.slider .slides {
		transform: none !important;
	}
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
	.slider,
	.slider .slides {
		height: 410px;
	}
}

@media only screen and (min-device-width: 320px) and (max-device-width: 568px) {
	.slider,
	.slider .slides {
		height: 500px;
	}
}

@media only screen and (min-device-width: 375px) and (max-device-width: 667px) {
	.slider,
	.slider .slides {
		height: 600px;
	}
}

@media only screen and (min-device-width: 414px) and (max-device-width: 736px) {
	.slider,
	.slider .slides {
		height: 670px;
	}
}
</style>
